import React from "react"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = ({ author }) => {
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: "2em",
      }}
    >
      {author.image != "" ? (
        <img
          src={author.image}
          alt={author.name}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            width: 75,
            height: 75,
            borderRadius: `100%`,
          }}
        />
      ) : (
        ""
      )}
      <p>
        Written by <strong>{author.name}</strong>
        <br />
        <span
          style={{ whiteSpace: "pre-wrap" }}
          dangerouslySetInnerHTML={{ __html: author.summary }}
        />
      </p>
    </div>
  )
}

export default Bio
