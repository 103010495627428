import React from "react"
import { Link, graphql } from "gatsby"
import { DiscussionEmbed } from "disqus-react"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

export const disqusConfig = ({ slug, title }) => ({
  shortname: "campus24",
  config: { identifier: slug, title },
})

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const coverImage =
    post.frontmatter.coverImg !== null
      ? "url('" + post.frontmatter.coverImg.publicURL + "')"
      : ""
  const author = {
    name: post.frontmatter.author,
    summary: post.frontmatter.authorBio,
    image:
      post.frontmatter.authorImg !== null
        ? post.frontmatter.authorImg.childImageSharp.resize.src
        : "",
  }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="container">
        <article>
          <header
            className="pt-5"
            style={{
              backgroundImage: coverImage,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              style={{ backgroundColor: "rgba(255,255,255,.65)" }}
              className="py-5 my-5"
            >
              <h1
                style={{
                  textAlign: "center",
                  margin: 0,
                  fontSize: "3em"
                }}
              >
                {post.frontmatter.title}
              </h1>
              <h6
                style={{
                  margin: 0,
                  marginTop: "1em",
                  padding: 0,
                  textAlign: "center",
                  fontWeight: 900,
                }}
              >
                {post.frontmatter.date}
              </h6>
            </div>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
        </article>
        <div>
          <Bio author={author} />
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              <strong>
                {previous && (
                  <>
                    <h6>Previous Post</h6>
                    <Link
                      to={previous.fields.slug}
                      rel="prev"
                      style={{ textDecoration: "none" }}
                    >
                      ← {previous.frontmatter.title}
                    </Link>
                  </>
                )}
              </strong>
            </li>
            <li>
              <strong>
                {next && (
                  <>
                    <h6 style={{ textAlign: "right" }}>Next Post</h6>
                    <Link
                      to={next.fields.slug}
                      rel="next"
                      style={{ textDecoration: "none" }}
                    >
                      {next.frontmatter.title} →
                    </Link>
                  </>
                )}
              </strong>
            </li>
          </ul>
        </div>
        <hr/>
        <DiscussionEmbed {...disqusConfig({ location, siteTitle })} />
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        coverImg {
          publicURL
        }
        date(formatString: "MMMM DD, YYYY")
        description
        author
        authorBio
        authorImg {
          childImageSharp {
            resize(width: 75, height: 75) {
              src
            }
          }
        }
      }
    }
  }
`
